import * as React from "react"
import { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../utils/seo"
import axios from "axios"
import Table from 'react-bootstrap/Table';

function Sitemap() {

    let [pages, setPages] = useState([])
    console.log(pages)

    useEffect(() => {
        const headers = {
            headers: {
                Authorization: `Bearer 55e43101c0037b4ceafbc23c583671e803e1e306f1cddadf60e89f20bc6fb06886a6e9b6f977d0187040f5d27086a184f9b79d22073309bbb298c448b1470361f178882a9fdc7583806dc5e8d2d61cec50fc22333ed4f24973b5b6b799d63df56f3d76b04a3b7ef8e5e3eedac3e97a0ee80191b1eebb2225a885fe664d25585f`
            }
        }

        const fetchPages = async () => {
            try {
                const { data } = await axios.get(`https://metagain-strapi-gf3zm.ondigitalocean.app/api/general-pages`, headers)
                setPages(data.data)

            } catch {

                console.log('Fetching Error')
            }
        }
        fetchPages()

    }, [])

    return (
        <Layout>

            <p>{`共 ${pages.length} 頁`}</p>


            <Table striped bordered hover variant="light">
                <tbody>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Slug</th>
                        <th>Content</th>
                    </tr>
                    {
                        pages.map((page) => {
                            return (
                                    <tr key={page.id}>
                                    <td>{page.id}</td>
                                    <td>{page.attributes.title}</td>
                                    <td>{'/' + page.attributes.slug}</td>
                                    <td>{page.attributes.content}</td>
                                    </tr>
                            );
                        })
                    }

                </tbody>
            </Table>
        </Layout>
    )
}
export const Head = () => <Seo title="網頁地圖" />


export default Sitemap
